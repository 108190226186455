var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"common-dialog":_vm.mailDialog,"dialog-width":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"body",fn:function(){return [_c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-form',{ref:"mail_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit()}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-layout',{staticClass:"mb-4"},[_c('v-flex',{staticClass:"my-auto",attrs:{"md3":""}},[_c('label',{staticClass:"font-weight-500 font-size-16 required"},[_vm._v("Recipients")])]),_c('v-flex',{attrs:{"md9":""}},[_c('v-combobox',{attrs:{"chips":"","items":_vm.autocompleteEmails(),"placeholder":"Recipients","label":"Recipients","multiple":"","flat":"","disabled":_vm.formLoading,"hide-details":"","solo":"","rules":[
                _vm.validateRules.required(_vm.email.recipients, 'Recipients') ]},on:{"change":function($event){return _vm.validateTagEmail(_vm.email.recipients)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-chip',{staticClass:"append-outer-chip",attrs:{"label":"","disabled":_vm.formLoading,"color":_vm.emailCCShow ? 'green' : '',"text-color":_vm.emailCCShow ? 'white' : ''},on:{"click":function($event){_vm.emailCCShow = !_vm.emailCCShow}}},[_c('span',{staticClass:"font-weight-500 font-size-16"},[_vm._v("CC")])])]},proxy:true},{key:"selection",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var select = ref.select;
              var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"label":"","input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeEmail(item, 'recipients')}}},'v-chip',attrs,false),[_c('span',{staticClass:"font-weight-500 font-size-16"},[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.email.recipients),callback:function ($$v) {_vm.$set(_vm.email, "recipients", $$v)},expression:"email.recipients"}})],1)],1),(_vm.emailCCShow)?_c('v-layout',{staticClass:"my-4"},[_c('v-flex',{staticClass:"my-auto",attrs:{"md3":""}},[_c('label',{staticClass:"font-weight-500 font-size-16"},[_vm._v("CC")])]),_c('v-flex',{attrs:{"md9":""}},[_c('v-combobox',{attrs:{"chips":"","disabled":_vm.formLoading,"items":_vm.autocompleteEmails(),"placeholder":"CC","label":"CC","multiple":"","flat":"","hide-details":"","solo":""},on:{"change":function($event){return _vm.validateTagEmail(_vm.email.cc)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var select = ref.select;
              var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"label":"","input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeEmail(item, 'cc')}}},'v-chip',attrs,false),[_c('span',{staticClass:"font-weight-500 font-size-16"},[_vm._v(_vm._s(item))])])]}}],null,false,2344880542),model:{value:(_vm.email.cc),callback:function ($$v) {_vm.$set(_vm.email, "cc", $$v)},expression:"email.cc"}})],1)],1):_vm._e(),_c('v-layout',{staticClass:"my-4"},[_c('v-flex',{staticClass:"my-auto",attrs:{"md3":""}},[_c('label',{staticClass:"font-weight-500 font-size-16 required"},[_vm._v("Subject")])]),_c('v-flex',{attrs:{"md9":""}},[_c('v-text-field',{attrs:{"dense":"","filled":"","label":"Subject","placeholder":"Subject","solo":"","flat":"","disabled":_vm.formLoading,"hide-details":"","color":"cyan","rules":[_vm.validateRules.required(_vm.email.subject, 'Subject')]},model:{value:(_vm.email.subject),callback:function ($$v) {_vm.$set(_vm.email, "subject", $$v)},expression:"email.subject"}})],1)],1),_c('v-layout',{staticClass:"my-4"},[_c('v-flex',{attrs:{"md12":""}},[_c('TinyMCE',{attrs:{"disabled":_vm.formLoading},model:{value:(_vm.email.message),callback:function ($$v) {_vm.$set(_vm.email, "message", $$v)},expression:"email.message"}})],1)],1),_c('v-layout',{staticClass:"mt-4"},[_c('v-flex',{attrs:{"md12":""}},[_c('FileTemplate',{attrs:{"disabled":_vm.formLoading,"allowUpload":""},on:{"file:updated":function($event){return _vm.updateAttachment($event)}}})],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid,"color":"cyan"},on:{"click":function($event){return _vm.submit()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send mdi-rotate-315")]),_vm._v("Send ")],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }